<template>
    <div>

    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        mounted() {
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "首页"
            document.getElementById("bodyId").appendChild(div);
        }
    }
</script>

<style lang="scss" scoped>

</style>
